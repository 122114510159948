import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction002 from './Introduction002'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0016 = () => (
  <Wrapper>
    <ColumnH1 label="常駐先企業と自社、どっちの就業規則が適用される？注意点は？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        所属先の企業とは別の場所で働く客先常駐社員の場合、所属先・常駐先のどちらの会社のルールに従うべきなのか、迷うケースが多くみられるのが現状です。たとえば、始業・終業時刻や労働時間の設定が異なる会社同士で行われる客先常駐の場合、何時に出社し、何時に退社をすれば定時になるのか、残業代はどうなるのか、などの疑問が挙げられます。
        <br />
        <br />
        今回は、客先常駐社員が守るべき会社のルールはどうなるのか、どの就業規則が適用されるか、などの疑問について、順を追って解説していきましょう。
      </p>
      <img src="/images/column/details/c0016.jpg" alt="キンクラコラム画像16" />
      <h1>客先常駐が守るべき就業規則とは</h1>
      <p>
        客先常駐には、成果物の完了を条件に客先常駐を行う
        <span>「請負契約」</span>
        と、専門的知識などを有する者が労働力を提供することを条件に客先常駐を行う
        <span>「準委任契約」</span>の2種類があります。
        <br />
        <br />
        この2種類は、契約の内容は大きく異なるものの、いずれも客先常駐社員へ業務の指示を行うのは、その常駐社員が所属する会社です。つまり、
        <span>客先常駐の社員に対して適用される就業規則は、自社の就業規則</span>
        となります。
      </p>
      <h1>客先常駐の実施時における注意点</h1>
      <p>
        客先常駐の社員が実際に働く現場は、原則として常駐先企業です。
        <br />
        したがって、常駐先企業に所属する社員とともに日々を過ごすこととなるため、当然ながら常駐をしている期間については、かかわりが深まる対象は常駐先企業となります。
        <br />
        <br />
        ここで気をつけなければならないのが、自社と常駐先との間で就業規則のルールに相違がある場合は、
        <span>必ず事前にすり合わせを行っておかなければならない</span>
        、という点です。
        <br />
        <br />
        たとえば、自社の所定労働時間が1日あたり7時間、常駐先企業の所定労働時間が1日あたり8時間であるとしましょう。ここでいう所定労働時間とは、労働基準法で決められている法定労働時間の範囲内で、各会社が任意で決定することのできる労働時間のことです。
        <br />
        <br />
        常駐社員は、自社にいる時は他の同僚と同じく1日に7時間働けば、所定労働時間分働いたことになります。しかし、その社員が常駐先の企業で1日に8時間働く社員とともに8時間の勤務を行った場合は、1時間分の
        <span>「残業</span>」を行ったという扱いになります。
        <br />
        今回の例における残業は、法定労働時間内の残業となる
        <span>「法内残業」</span>
        であることから、割増賃金の支払義務は生じませんが、中には任意で法内残業に対する残業代の支払いを定めている企業もあることから、注意をしなければなりません。
        <br />
        <br />
        このようなケースを防ぐために自社企業が取るべき対応としては、まず常駐先企業の社内ルールを確認し、自社との相違点について洗い出しを行います。もしも異なる社内ルールがある場合は、その社員の扱いはどうするかについて、入念に話し合いの上で一つずつ
        <span>ルールを決めていく</span>必要があります。
        <br />
        あくまでも常駐社員が守るべきは自社の就業規則であることを念頭に置き、常駐先において残業や休日出勤が避けられない事態となった場合は、その労働分の割増賃金を計算しなければなりません。
      </p>
      <Introduction002 />
      <h1>客先常駐社員に対する労務管理の重要性</h1>
      <p>
        社外で業務を行う客先常駐社員に対して就業規則の内容を適用するためには、常駐社員の労務管理を徹底して行わなければならないという必要性が生じます。
        <br />
        実際に自社内で働く社員と比較すると、常に社外にいる社員の就労状況を把握することは難しいものです。極端な話、常駐社員がいつ出社し、いつ退社したなどの基本的な情報を目視することができないためです。
        <br />
        <br />
        このような状況への対策法として、労務管理ソフトやクラウドシステム等を活用し、
        <span>常に客先常駐社員の状況を把握する</span>方法が挙げられます。
        <br />
        昨今ではコロナ禍の影響もあり、在宅勤務でテレワークを実施する者が非常に増加しており、それに伴いリモートで労務管理を行うことのできるシステムも日々進化しています。出社時刻、退社時刻の記録に加え、常駐先でどのような作業をしているかを把握するために一定時刻ごとに業務用パソコンの画面スクリーンショットが記録されるシステムもあるため、自社の状況に合わせて検討をすると良いでしょう。
        <br />
        <br />
        なお、常駐社員の就業時間を把握する方法については、自社システムの他にも、常駐先のタイムチェック機能を活用させてもらう方法などもあります。
        <br />
        ただ、常駐先のシステムを活用するには先方の了解を得る必要があり、中には管理維持費などの費用負担を要する可能性もありますので、
        <span>常駐先と相談の上で具体的な方法を定めていく</span>
        ことが重要となります。
      </p>
      <h1 className="read">まとめ</h1>
      <p>
        客先常駐社員は、他社で業務をしながら自社の就業ルールに従うという特殊な立場で働くことになるため、就業環境の整備や労務管理の徹底は常駐元の企業にとって必要不可欠なものです。
        <br />
        業務内容や情勢の変化により、常駐社員を取りまく環境も日々変化していることから、常駐元・常駐先の企業が連携し、よりスムーズに業務が行えるような形を構築していかなければなりません。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0016
