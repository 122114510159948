import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0016 from '../../../components/molecules/columnDetailInner0016'
import UsefulFunctionLayout014 from '../../../components/molecules/usefulFunctionLayout014'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0016 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '常駐先企業と自社の就業規則が違う場合にどっちが適用されるか？',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="常駐先企業と自社、どっちの就業規則が適用される？注意点は？"
        description="客先常駐社員の場合、自社・常駐先それぞれのルールが存在します。自社と常駐先との間で就業規則のルールに相違がある場合は、必ず事前にすり合わせをすることが大切です。"
        ogUrl="https://kintaicloud.jp/column/details/C0016/"
        ogType="article"
        ogTitle="常駐先企業と自社、どっちの就業規則が適用される？注意点は？"
        ogDescription="客先常駐社員の場合、自社・常駐先それぞれのルールが存在します。自社と常駐先との間で就業規則のルールに相違がある場合は、必ず事前にすり合わせをすることが大切です。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0016.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0016 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout014 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0016

export const pageQuery = graphql`
  query C0016 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
